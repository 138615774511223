import { createSlice } from "@reduxjs/toolkit"

const initialGlobalState = {
  siteSettings: null,
}

const globalSlice = createSlice({
  name: "global",
  initialState: initialGlobalState,
  reducers: {
    setSiteSettings(state, action) {
      state.siteSettings =
        typeof action.payload !== "undefined" ? action.payload : null
      return state
    },
  },
})

export const globalActions = globalSlice.actions

export default globalSlice.reducer
